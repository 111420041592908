import { Component, Input, OnInit } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { Subscription } from 'rxjs';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';

@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss'],
})
export class MyTeamComponent implements OnInit {
  private subscription = new Subscription();
  isLoading = false;
  myTeamList: any = null;
  noResult = false;
  errorResponseCode: any;
  constructor(private transactionManagerService: TransactionManagerService) {}
  @Input() value: string;
  @Input() transactionId: string;

  ngOnInit(): void {
    this.getMyTeams();
  }

  private getMyTeams() {
    this.subscription.add(
      this.transactionManagerService.getServiceProviders(this.transactionId).subscribe(
        (response) => {
          this.isLoading = false;
          if (response && response.length) {
            this.myTeamList = response;
            this.noResult = false;
            console.log('My Team List', this.myTeamList);
          } else {
            this.noResult = true;
            const errorResponse = response.errors[0];
            this.errorResponseCode = errorResponse.extensions.errorCode;
            datadogLogs.logger.error('::MyTeamComponent::getMyTeams::StatusResponse', { message: errorResponse});
          }
        },
        (err) => {
          this.isLoading = false;
          this.noResult = true;
          this.errorResponseCode = 404;
          datadogLogs.logger.error('::MyTeamComponent::getMyTeams::API Error', { message: err});
        }
      )
    );
  }

  toggleAccordion(event, index) {
    let element = event.target;
    if(element.classList.contains('float-left') || element.classList.contains('float-right')) {
      element = element.parentElement;
    } else {
      if(element.classList.contains('mat-icon') || element.classList.contains('btn')) {
        element = element.parentElement.parentElement
      }
    }
    element.classList.toggle('active');
    if (this.myTeamList[index].isActive) {
      this.myTeamList[index].isActive = false;
    } else {
      this.myTeamList[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  getValidUrl(url) {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }
    return newUrl;
  }

}
