
  <div class="content" id="content-wrap">
  <div class="component-content">
    <div class="message-wrapper">
      <div class="page-no-result">
        <h1 class="error-page-header">{{pageTitle}}</h1>
      </div>
    </div>
    <div class="image-wrapper">
      <img src="assets/images/Empty State-HP_TM.svg">
    </div>
  </div>
  </div>
