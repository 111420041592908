import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-no-result-page',
  templateUrl: './no-result-page.component.html',
  styleUrls: ['./no-result-page.component.scss']
})
export class NoResultPageComponent implements OnInit {
  constructor( public route: ActivatedRoute) { }

  @Input() pageTitle: any = 'There are no documents to view, yet.';

  ngOnInit(): void {    
  }
}
