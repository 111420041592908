import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import * as fromApp from './app.reducers';
import * as fromOkta from './okta.reducers';


export interface OktaState {
  okta: fromOkta.OktaState;
}

export interface AppState {
  appState: fromApp.AppState;
}

export interface State {
  okta: fromOkta.OktaState;
  appState: fromApp.AppState;
}

export const reducers: ActionReducerMap<State> = {
  okta: fromOkta.oktaReducers,
  appState: fromApp.appReducers,
};

const combineReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  return combineReducer(state, action);
}



