<div class="login-blk">
    <mat-card >
        <mat-card-header>
            <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>
            Press the login button to proceed further
            </p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button mat-raised-button color="primary" (click)="login()">Login</button>
        </mat-card-actions>
    </mat-card>
</div>
