import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs-compat';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';
import { datadogLogs } from '@datadog/browser-logs';
@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss'],
})
export class TodoComponent implements OnInit {

  private subscription = new Subscription();
  isLoading = false;
  todoList: any = null;
  noResult = false;
  errorResponseCode: any;
  showOverDue = false;
  dateDiff = 0;
  positiveDate=0;
  @Input() transactionId: string;
  
  constructor(private transactionManagerService: TransactionManagerService) {}

  ngOnInit(): void {
    this.getTodos();
  }

  toggleAccordion(event, index) {
    let element = event.target;

    if(element.classList.contains('tit-content') || element.classList.contains('tit-icon')) {
      element = element.parentElement;
    }
    element.classList.toggle('active');
    if (this.todoList[index].isActive) {
      this.todoList[index].isActive = false;
    } else {
      this.todoList[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  private getTodos() {
    this.subscription.add(
      this.transactionManagerService.getTodos(this.transactionId).subscribe(
        (response) => {
          datadogLogs.logger.info('::TodoComponent::getTodos::Response', { message: 'Success'});
          this.isLoading = false;
          if (response && response.length) {
            this.todoList = response;
            this.noResult =false;
          } else {
            this.noResult =true;
            const errorResponse = response.errors[0];
            this.errorResponseCode = errorResponse.extensions.errorCode;
            datadogLogs.logger.error('::TodoComponent::getTodos::StatusResponse', { message: errorResponse});
          }
        },
        (err) => {
          this.isLoading = false;
          this.noResult =true;
          this.errorResponseCode = 404;
          datadogLogs.logger.error('::TodoComponent::getTodos::API Error', { message: err});
        }
      )
    );
  }
  getDateFormat(date){
    let ads = new Date(date.slice(0,-1));
    return ads.toLocaleDateString('en-US');
  }

  getDateDiff(date){
    const currentTime: any = new Date();
    const dueDate: any = new Date(date);
    this.dateDiff =  Math.floor(( currentTime - dueDate) / (1000*60*60*24));
    this.positiveDate = Math.abs(this.dateDiff);
    return true;
  }
}
