import { Component, OnInit } from '@angular/core';
import { OktaAuthenticationProvider } from '../sevices/auth-providers/okta-authentication.provider';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: OktaAuthenticationProvider
  ) { }

  ngOnInit(): void {
    this.login();
  }
  login() {
    this.authService.redirectToLogin();
  }
}
