import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import * as browserDetection from 'detect-browser';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';


@Injectable()
export class ApplicationService {
  private browser: any;
  private screenWidth: any;
  private screenHeight: any;
  private screenWidth$ = new BehaviorSubject<number>(undefined);
  private deviceChanged$ = new Subject<any>();
  private currentPath = new BehaviorSubject<RouterEvent>(undefined);
  appVersion$ = new Subject<string>();
  private router: Router;
  private isPhone: boolean;
  private isTablet: boolean;
  private isTouchDevice: boolean;

  public load = false;
  public isTouchDevice$ = new BehaviorSubject<boolean>(null);

  // Domain flags
  public domain = {
    isCB: false,
    isZIP: false,
    isCBC: false,
    isLocal: false,
    isRP: false,
  };

  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.screen.height;
    this.screenWidth$.next(this.screenWidth);
    this.browser = browserDetection.detect();
    this.isPhone = this.isPhoneDevice();
    this.isTablet = this.isTabletDevice();
    this.getDomain();
    this.setDeviceType();
  }


  getBrowserName() {
    return this.browser.name.toLowerCase().trim();
  }

  isPhoneDevice() {
    return this.screenWidth <= environment.mobileWidthMax;
  }

  isTabletDevice() {
    return this.screenWidth > environment.mobileWidthMax && this.screenWidth <= environment.tabletWidthMax;
  }

  isIpad() {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  }

  setScreenWidth(width): void {
    this.screenWidth = width;
    this.screenWidth$.next(width);

    if (this.isPhone !== this.isPhoneDevice() || this.isTablet !== this.isTabletDevice()) {
      this.deviceChanged$.next('change');
    }

    this.isPhone = this.isPhoneDevice();
    this.isTablet = this.isTabletDevice();
  }

  getScreenWidth(): number {
    return this.screenWidth;
  }

  getCurrentPath() {
    return this.currentPath.asObservable();
  }

  getCurrentPathSubject() {
    return this.currentPath;
  }

  setCurrentPath(path: RouterEvent) {
    this.currentPath.next(path);
  }

  getScreenHeight(): number {
    return this.screenHeight;
  }

  getScreenWidthSubject(): Subject<any> {
    return this.screenWidth$;
  }

  getScreenWidthAsObservable(): Observable<any> {
    return this.screenWidth$.asObservable();
  }

  getDeviceChangedSubject(): Subject<any> {
    return this.deviceChanged$;
  }

  getDeviceChangedAsObservable(): Observable<any> {
    return this.deviceChanged$.asObservable();
  }

  getAppVersion(): Observable<string> {
    return this.appVersion$.asObservable();
  }

  getDomain() {
    const hostName = window.location.hostname;

    switch (true) {
      case hostName.includes('localhost'):
        this.domain.isLocal = true;
        break;
      case hostName.includes('mycbdesk'):
        this.domain.isCB = true;
        break;
      case hostName.includes('myzipdesk'):
        this.domain.isZIP = true;
        break;
      case hostName.includes('cbcdesk'):
        this.domain.isCBC = true;
        break;
      case hostName.includes('realogyproduct'):
        this.domain.isRP = true;
        break;
    }
  }

  setDeviceType() {
    this.isTouchDevice = 'ontouchstart' in document.documentElement;
    this.isTouchDevice$.next(this.isTouchDevice);
  }

  getIsTouchDevice() {
    return this.isTouchDevice;
  }
}
