import { AfterViewChecked, AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApplicationService } from './sevices/application.service';
import { OktaAuthenticationProvider } from './sevices/auth-providers/okta-authentication.provider';
import { RscClientService } from './sevices/rsc-client.service';
import { SidenavState } from './shared/models/sidenav-state.model';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { AuthenticationService } from './sevices/authentication.service';

import { datadogLogs } from '@datadog/browser-logs';

function _window(): any {
  return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'homeplace';
  router$: Subscription;

  private rscClient: any;
  public mounted = false;
  public isSetMenu = false;
  user;

  mobile: boolean;
  tablet: boolean;
  isAnonymous: any;
  pagePath: string;

  constructor(
    private ngZone: NgZone,
    private authService: OktaAuthenticationProvider,
    private authenticationService: AuthenticationService,
    private route: Router,
    private appService: ApplicationService,
    private rscClientService: RscClientService
    ) {
  }

  ngOnInit() {
    this.ngZone.run(() => this.mounted = true);
    datadogLogs.logger.info('::AppComponent::onInit', { message: 'test'});
    this.authService.isAuthenticatedObs().pipe(
      map(authenticated => {
        const token = this.authService.getToken();
        if (token) {
          this.rscClient.setAccessToken(token.accessToken);
        }
        return authenticated;
      })
    ).subscribe(userData => {
      if (userData) { 
      }
    });

    this.router$ = this.route.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd || event instanceof RoutesRecognized)
      )
      .subscribe(event => {
        if (event instanceof RoutesRecognized) {
          const routeData = event.state.root.firstChild.data;

          if (routeData) {

            if (this.isAnonymous !== routeData.anonymous) {
              this.isAnonymous = routeData.anonymous;
            }
          }
        }
        if (event instanceof NavigationEnd) {
          this.pagePath = event.url;
          if (this.appService.getIsTouchDevice()) {
            this.rscClientService.toggleSideNav(false);
          }
          this.rscClientService.setCurrentUrl(event.url);
        }
      });
  }

  ngAfterViewInit() {
   this.rscClient =  _window().__RSC__;
   this.authService.getUser().subscribe(user => {
      if (user) {
        if (this.rscClient && !this.isSetMenu && this.authService.userName) {
          const accessToken = this.authService.getToken();
          if (accessToken) { this.rscClient.setAccessToken(accessToken.accessToken); }
          this.rscClient.setUsername(this.authService.userName);
          this.rscClient.setOktaId(this.authService.oktaId);
          this.rscClient.showUserNameInTopnav(true);
          this.isSetMenu = true;
        }
      }
    });
  }

  redirectToLogout() {
    this.ngZone.run(() => {
      this.route.navigate(['okta/logout']);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onDeviceChange(event);
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    this.onDeviceChange(event);
  }

  onDeviceChange(event?) {
    const widthToSend = event ? event.target.innerWidth : window.innerWidth;
    this.appService.setScreenWidth(widthToSend);
    this.mobile = this.appService.isPhoneDevice();
  }

}
