import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs-compat';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';

@Component({
  selector: 'app-listing-info',
  templateUrl: './listing-info.component.html',
  styleUrls: ['./listing-info.component.scss']
})
export class ListingInfoComponent implements OnInit {
  constructor(private transactionManagerService: TransactionManagerService,) { }
  @Input() details: any;
  value: string = 'test';
  address: any;
  propertyDetails: any;
  dateDiff:any;
  private subscription = new Subscription();
  ngOnInit(): void {
    this.propertyDetails = this.details.property;
    this.address = this.details.property.address;
    this.dateDiff = this.getDateDiff(this.details.estimatedClosingDate);
  }

  getDateFormat(date){
    let ads = new Date(date.slice(0,-1));
    return ads.toLocaleDateString("en-US");
  }

  getDateDiff(date){
    const currentTime: any = new Date();
    const dueDate: any = new Date(date.slice(0,-1));
    const diffDays =  Math.ceil(( dueDate - currentTime) / (1000*60*60*24));
    return diffDays;
  }
}
