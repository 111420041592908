<div *ngIf="!noResult">
<div *ngFor="let item of myTeamList; let i = index" class="accordion-item">
  <button class="accordion" (click)="toggleAccordion($event, i)">
    <span class="float-left">
    {{ item.category }} </span> <span class="float-right btn-container">
      <div class="btn accepted" *ngIf="item.is_affiliated">Accepted</div>
      <div class="btn" *ngIf="!item.is_affiliated">Suggested</div>
    </span>
  </button>
  <div class="panel" hide="!item.isActive">
    <!-- <div class="left-card">
      <img src="{{ item.logoURL }}" />
      <div class="btn-container">
        <div class="btn">Status</div>
      </div>
    </div> -->
    <div class="right-card">
      <mat-list dense class="title-list">
        <mat-list-item *ngIf="item.companyName">{{ item.companyName }}</mat-list-item>
        <mat-list-item *ngIf="item.contactName">{{ item.contactName }}</mat-list-item>
      </mat-list>
      <mat-list dense class="contact-info">
        <mat-list-item *ngIf="item.emailId"
          ><mat-icon>mail</mat-icon><span>Email &#183;</span
          >{{ item.emailId }}</mat-list-item
        >
        <mat-list-item *ngIf="item.contactNo"
          ><mat-icon>phone</mat-icon><div class="phone-no">{{item.contactNo | phone:'US'}}</div></mat-list-item
        >
        <mat-list-item *ngIf="item.webLink && item.webLink "
          ><mat-icon>language</mat-icon
          ><a target="_blank" href="{{getValidUrl(item.webLink)}}">
            <div >{{item.webLink}}</div>
          </a></mat-list-item
        >
      </mat-list>
    </div>
  </div>
</div>
</div>
<div *ngIf="noResult" class="empty-state">
  <app-no-result-page pageTitle="There are no Team to view, yet." ></app-no-result-page>
  </div>