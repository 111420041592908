import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { OktaAuthenticationProvider } from 'src/app/sevices/auth-providers/okta-authentication.provider';

@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {

  constructor(
    private oktaAuthService: OktaAuthenticationProvider,
    private router: Router,
    private okta: OktaAuthenticationProvider
  ) { }

  async ngOnInit() {
  try {
    if ('localStorage' in window && window['localStorage'] !== null)
        {
          const token = await this.oktaAuthService.loadToken();
          if (token) {
            this.router.navigate(['login', 'callback']);
          } else {
            this.oktaAuthService.login();
          }
        }
  } catch (e) {
    datadogLogs.logger.error('::OktaLoginComponent::OnInit::API Error', { message: e});
  }
  }
}
