// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiBaseURL: 'https://preprod1.api.realogy.com/',
  apikey: 'V4MaKysFA8irmSwOr7MwLOmr1BB7ijez',
  unsupportedBroowserUrl: '',
  snackbarTimeOut: 2000,
  oktaAuthEnabled: true,
  oktaTenantUrl: 'https://realogy.oktapreview.com',
  oktaIssuer: 'https://realogy.oktapreview.com/oauth2/ausdtpyw647fbrcPi0h7',
  oktaClientId: '0oa1ap4nzdd4okXAX0h8',
  oktaMaxClockSkew: (60 * 60 * 6), // 6 hours
  oktaRedirectPath: '/login/callback',
  brandedLoginPagesIDPs: {
    homeplace: '',
  },
  applicationName: 'RFGNRT Homeplace Portal SPA',
  enableConsoleLog: true,
  mobileWidthMax: 470,
  tabletWidthMax: 1024,
  dataDogClientId: 'pub0d835c071922da6ea83a3a7f4371f431',
  datadogSite: 'us5.datadoghq.com',
  datadogService: 'Home place Web',
  datadogEnv: 'dev',
  datadogHost: 'localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
