import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { Subscription } from 'rxjs-compat';
import { TransactionManagerService } from '../sevices/transaction-manager.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  constructor(private transactionManagerService: TransactionManagerService,
    public route: ActivatedRoute,) { }

  value: string = 'test';
  private subscription = new Subscription();
  isLoading = true;
  transactionDetails: any;
  transactionId;
  errorResponseCode: any;
  ngOnInit(): void {
    this.getQueryParams();
    this.getTransactionDetails();
    
  }

  getQueryParams() {
    this.transactionId = this.route.snapshot.queryParamMap.get('txnId');
    console.log('txnId : '+ this.route.snapshot.queryParamMap.get('txnId') );
  }

  private getTransactionDetails() {
    this.subscription.add(
      this.transactionManagerService.getTransactionDetails(this.transactionId).subscribe(
        response => {
          this.isLoading = false;
          if (response) {
            this.transactionDetails = response;
          } else {
            const errorResponse = response.errors[0];
            this.errorResponseCode = errorResponse['extensions'].errorCode;
            datadogLogs.logger.error('::ListingComponent::getTransactionDetails::StatusResponse', { message: errorResponse});
          }
        },
        err => {
          this.isLoading = false;
          this.errorResponseCode = 404;
          datadogLogs.logger.error('::ListingComponent::getTransactionDetails::API Error', { message: err});
        }
      )
    );
  }
}
