import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AppLoadModule } from './app-load.module';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { routing } from './app.routing';
import { MaterialModule } from './modules/material/material.module';
import { CreateEventModalComponent } from './shared/modals/create-event-modal/create-event-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackbarComponent } from './modules/material/snackbar/snackbar.component';
import { SnackbarService } from './sevices/snackbar.service';
import { OktaLoginComponent } from './authentication/okta/okta-login/okta-login.component';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './sevices/authentication.service';
import { OktaAuthenticationProvider } from './sevices/auth-providers/okta-authentication.provider';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { oktaReducers } from './store/reducers/okta.reducers';
import { effects } from './store/effects';
import { ApplicationService } from './sevices/application.service';
import { RscClientService } from './sevices/rsc-client.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { reducers } from './store';
import { LogoutComponent } from './logout/logout.component';
import { ListingComponent } from './listing/listing.component';
import { MyTeamComponent } from './listing/myteam/my-team.component';
import { documentComponent } from './listing/document/document.component';
import { MyJourneyComponent } from './listing/myjourney/my-journey.component';
import { AgentInfoComponent } from './listing/agentInfo/agent-info.component';
import { ListingInfoComponent } from './listing/listingInfo/listing-info.component';
import { TransactionManagerService } from './sevices/transaction-manager.service';
import { Apollo } from 'apollo-angular';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { HttpLink } from 'apollo-angular-link-http';
import { GraphQLModule } from './graphql/graphql.module';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { TodoComponent } from './listing/todo/todo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PhonePipe } from './shared/pipes/phone.pipe';
import {ErrorPageComponent} from './shared/error-page/error-page.component'
import {NoResultPageComponent} from './shared/no-result-page/no-result-page.component'

export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri: 'http://localhost:3000/' }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  declarations: [
    AgentInfoComponent,
    AppComponent,
    SnackbarComponent,
    LoginComponent,
    LogoutComponent,
    CreateEventModalComponent,
    OktaCallbackComponent,
    OktaLoginComponent,
    DashboardComponent,
    ListingComponent,
    MyTeamComponent,
    documentComponent,
    MyJourneyComponent,
    ListingInfoComponent,
    TodoComponent,
    ErrorPageComponent,
    NoResultPageComponent,
    PhonePipe
  ],
  imports: [
    CommonModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApolloModule,
    routing,
    BrowserModule,
    AppLoadModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    MatExpansionModule,
  ],
  providers: [
    Apollo,
    HttpLink,
    SnackbarService,
    AuthGuard,
    TransactionManagerService,
    AuthenticationService,
    ApplicationService,
    RscClientService,
    OktaAuthenticationProvider,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
