import { Injectable } from '@angular/core';
import { Snackbar } from '../shared/models/snackbar.model';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { SnackbarComponent } from '../modules/material/snackbar/snackbar.component';

/**
 * `service` to handle **Snackbars** from across the app.
 * If the action handling is needed, subscribe to `onAction()` observer.
 */
@Injectable()
export class SnackbarService {
  constructor(private handler: MatSnackBar) {}

  open(message: string,
       action?: string,
       verticalPos?: MatSnackBarVerticalPosition,
       duration: number = environment.snackbarTimeOut): MatSnackBarRef<SimpleSnackBar> {
    return this.handler.open(
      message, action,
      {duration: duration, horizontalPosition: 'right', verticalPosition: verticalPos ? verticalPos : 'top'}
    );
  }

  openDefaultError(action?: string, duration: number = environment.snackbarTimeOut): MatSnackBarRef<SimpleSnackBar> {
    return this.open(
      'Unfortunately something did not work as expected. Please try again later.',
      action,
      null,
      duration
    );
  }
  // * Public Methods

  /**
   * Dispatch the received `Snackbar` using the custom `SnackbarComponent`.
   * @param snackbar actual message to display.
   * @param duration currently using the duration defined in `environment` as **default**.
   * @returns `MatSnackBarRef` reference to handle its `onAction()` observer and do something **ONLY** when the action was
   * selected.
   */
  dispatch(snackbar: Snackbar, duration: number = environment.snackbarTimeOut): MatSnackBarRef<SnackbarComponent> {
    return this.handler.openFromComponent(SnackbarComponent, {
      duration,
      panelClass: 'snackbar-custom',
      data: snackbar,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

  /**
   * Dispatch a **default error** `Snackbar` using the custom `SnackbarComponent`.
   * @param duration currently using the duration defined in `environment` as **default**.
   * @param action look inside `Snackbar` for a set of values, if there is none use a `string` by **default**.
   * @returns `MatSnackBarRef` reference to handle its `onAction()` observer and do something **ONLY** when the action was
   * selected.
   */
  dispatchDefaultError(duration: number = environment.snackbarTimeOut, action?: string): MatSnackBarRef<SnackbarComponent> {
    const format: Snackbar = {
      action,
      message: 'Unfortunately something did not work as expected. Please try again later.',
      type: 'error'
    };

    return this.handler.openFromComponent(SnackbarComponent, {
      duration,
      panelClass: 'snackbar-custom',
      data: format,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
