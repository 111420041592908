<div *ngIf="!noResult">
<div *ngFor="let item of documentList; let i = index" class="accordion-item">
  <button class="accordion" (click)="toggleAccordian($event, i)">
    <div class="title" aria-hidden="true">
      {{ item.documentType }}
      <mat-icon *ngIf="item.status != 'Completed'" matTooltip="Needs Review">error_outline</mat-icon>
    </div>
  </button>
  <div class="panel" hide="!item.isActive">
    <p class="description"></p>
    <div class="list-content">
      <mat-list class="doc-list">
        <mat-list-item>
          <mat-icon matListIcon>{{
            item.status == 'Completed'
              ? 'check_circle'
              : 'radio_button_unchecked'
          }}</mat-icon>
          <p matLine class="doc-list-title">
            {{ item.documentName }}
          </p>
        </mat-list-item>
      </mat-list>
      <div class="inner-content" >
        <p *ngIf="item.uploadedBy">Uploaded By • <span>{{ item.uploadedBy }}</span></p>
        <p *ngIf="item.uploadedDate;else receivedInSource">
          Uploaded On • <span>{{ getDateFormat(item.uploadedDate) }}</span>
        </p>
        <ng-template #receivedInSource>
          <p *ngIf="item.receivedInSource">
            Uploaded On • <span>{{ getDateFormat(item.receivedInSource) }}</span>
          </p>
        </ng-template>
        <p>
          Status • <span>{{ getStatusFormat(item.status) }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<div *ngIf="noResult" class="empty-state">
  <app-no-result-page pageTitle="There are no documents to view, yet." ></app-no-result-page>
</div>
