<div id="app-body">
  <!--<rsc-side-nav *ngIf="mounted" offset-height="45px"></rsc-side-nav> -->
  <rsc-header *ngIf="mounted && false" app-key="caa6bcae-47fa-4002-a045-35d1130bc7ab" brand-key="cb" main-class="main" full-width-layout="true" profile-menu="true"></rsc-header>


    <div class="app-container">
      <div class="body-content">
        <main>
            <router-outlet></router-outlet>
        </main>
      </div>
      <!-- <rsc-footer></rsc-footer> -->
    </div>
</div>
