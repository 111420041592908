import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OktaAuthenticationProvider } from 'src/app/sevices/auth-providers/okta-authentication.provider';
import { SnackbarService } from 'src/app/sevices/snackbar.service';
import * as fromStore from '../../../store';
import { App } from '../../models/app.model';

@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss']
})

export class CreateEventModalComponent implements OnInit, OnDestroy {

  categoryList = '';
  priorityList = Array.from(Array(5).keys(), n => n + 1);
  viewMode = false;

  createEventForm = new FormGroup({
    // id: new FormControl(''),
    userOktaId: new FormControl(''),
    category: new FormControl('', [Validators.required]),
    priority: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    description: new FormControl(''),
    source: new FormControl('', [Validators.required]),
  });

  dataSource: any;

  public filteredApps;

  private registeredApps: Array<App> = [];
  private subscriptions$ = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<CreateEventModalComponent>,
    private snackbarService: SnackbarService,
    private authService: OktaAuthenticationProvider,
    private appsStore: Store<fromStore.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.assignFormData();
    this.listenToControls();
  }

  assignFormData() {

    this.getRegisteredApps();

    this.createEventForm.controls.userOktaId.setValue(this.authService.oktaId);
    this.createEventForm.controls.startDate.setValue(new Date());

    if (this.data) {
      this.viewMode = true,
        this.createEventForm.controls.userOktaId.setValue(this.data.userOktaId);
      this.createEventForm.controls.category.setValue(this.data.category);
      this.createEventForm.controls.priority.setValue(this.data.priority);
      this.createEventForm.controls.startDate.setValue(this.data.startDate);
      this.createEventForm.controls.endDate.setValue(this.data.endDate);
      this.createEventForm.controls.description.setValue(this.data.description);
      this.createEventForm.controls.source.setValue(this.data.Source?.displayName);
    }
  }

  getRegisteredApps() {
    this.appsStore.dispatch(new fromStore.GetRegisteredApps());

    this.subscriptions$.add(
      this.appsStore.select(state => state.appState.registeredApps).subscribe(registeredApps => {
        this.registeredApps = registeredApps;
        this.filteredApps = this.registeredApps;
      })
    );
  }

  listenToControls() {
    this.subscriptions$.add(
      this.createEventForm.controls.source.valueChanges
        .pipe(debounceTime(250))
        .subscribe((displayName: string) => {

          this.filteredApps = this._filterApps(displayName);

          if (this.filteredApps.length < 1) {
            this.createEventForm.controls.source.setErrors({ invalid: true });
          } else if (displayName.length) {
            this.createEventForm.controls.source.setErrors(null);
          }
        })
    );
  }

  create() {
    if (this.createEventForm.valid) {

      const sourceApp = this.registeredApps.find(
        option => option.displayName.toLowerCase().includes(this.createEventForm.controls.source.value.toLowerCase())
      );
    }
  }

  clone() {
    this.viewMode = false;
    this.createEventForm.controls.startDate.setValue(new Date());
    this.snackbarService.open('Event has been cloned successfully');
  }

  cancel() {
    this.dialogRef.close();
  }

  private _filterApps(displayName: string): Array<App> {
    const filterValue = displayName?.toLowerCase();
    return this.registeredApps.filter(option => option.displayName.toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}
function startWith(arg0: string): any {
  throw new Error('Function not implemented.');
}

