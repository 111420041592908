import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { datadogLogs as datadog } from '@datadog/browser-logs';

if (environment.production) {
  enableProdMode();
}

datadog.init({
  clientToken: environment.dataDogClientId,
  site: environment.datadogSite,
  service: environment.datadogService,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: environment.datadogEnv
});
datadog.addLoggerGlobalContext('host', environment.datadogHost);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

