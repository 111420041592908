<div class="mat-h2"><span *ngIf="viewMode">View</span> <span *ngIf="!viewMode">Create</span> Event</div>
<p><span *ngIf="viewMode">View the event which was </span> <span *ngIf="!viewMode">Create an event as if it was
    being</span> sent from a specific application.</p>
<form [formGroup]="createEventForm" (ngSubmit)="createEventForm.valid && create()" [ngClass]="{'view-mode': viewMode}">
  <div mat-dialog-content>
    <div class="form-wrapper">
      <div>
        <mat-form-field appearance="outline">
          <mat-label class="placeholder">User Okta Id</mat-label>
          <input matInput formControlName="userOktaId" #userOktaIdInput="matInput" autocapitalize="off">
          <mat-hint> The user that this event pertains to. If not given, the event is for all users. </mat-hint>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Source</mat-label>
          <input type="text" matInput formControlName="source" [matAutocomplete]="auto" [readonly]="viewMode">
          <mat-autocomplete #auto="matAutocomplete">
            <ng-container *ngIf="filteredApps !== null; else appLoadingTemplate">
              <mat-option *ngFor="let option of filteredApps" [value]="option.displayName">
                {{option.displayName}}
              </mat-option>
            </ng-container>

            <ng-template #appLoadingTemplate>
              <mat-option>
                <mat-spinner [diameter]="30"></mat-spinner>
              </mat-option>
            </ng-template>
          </mat-autocomplete>

          <mat-error *ngIf="createEventForm.controls.source.invalid">Please enter a valid Source
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-label class="placeholder">Category</mat-label>
          <input matInput formControlName="category" [readonly]="viewMode" *ngIf="viewMode">
          <mat-select formControlName="category" *ngIf="!viewMode">
            <mat-option *ngFor="let item of categoryList | keyvalue" [value]="item.value">
              {{item.key | titlecase}}
            </mat-option>
          </mat-select>
          <mat-hint [id]="''"> General type of event. </mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description" [readonly]="viewMode"></textarea>
          <mat-hint>The descriptive name of the event that can be used by Apps for filtering and determining how to
            handle the event.</mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label class="placeholder">Priority</mat-label>
          <input matInput formControlName="priority" [readonly]="viewMode" *ngIf="viewMode">
          <mat-select formControlName="priority" *ngIf="!viewMode">
            <mat-option *ngFor="let item of priorityList | keyvalue" [value]="item.value">
              {{item.key}}
            </mat-option>
          </mat-select>
          <mat-hint [id]="''">Importance of the event, 0 = unimportant, 4 = extremely urgent.</mat-hint>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Start Date</mat-label>
          <input matInput formControlName="startDate" [readonly]="viewMode" [matDatepicker]="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate" *ngIf="!viewMode"></mat-datepicker-toggle>
          <mat-datepicker #startDate touchUi=true></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>End Date</mat-label>
          <input matInput formControlName="endDate" [readonly]="viewMode" [matDatepicker]="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate" *ngIf="!viewMode"></mat-datepicker-toggle>
          <mat-datepicker #endDate touchUi=true></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button type="button" (click)="clone()" *ngIf="viewMode">Clone</button>
    <button mat-raised-button color="primary" type="button" (click)="cancel()" *ngIf="viewMode">Close</button>
    <button mat-flat-button type="button" (click)="cancel()" *ngIf="!viewMode">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!createEventForm.valid"
      *ngIf="!viewMode">Submit</button>
  </div>
</form>