import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs-compat';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  constructor( public route: ActivatedRoute) { }

  pageTitle: any = 'We would love to let you in!';
  pageContent1: any = 'However, this space cannot be viewed in incognito mode.';
  pageContent2: any = 'Please turn off the incognito mode in your browser to view.';

  ngOnInit(): void {    
  }
}
