import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import {
  GetDetailsById } from './queries';

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    GetDetailsById,
  ]
})
export class GraphQLModule {}
