
<div class="listing-content">
  <div class="item-per"><h2 *ngIf="details">HomePlace Consumer View <span class="status">Read Only</span></h2>
  
</div>
  <h4>
    <span *ngIf="address.addressLine1">{{address.addressLine1}}</span>
    <span *ngIf="address.addressLine2">{{address.addressLine2}}</span>
    <span *ngIf="address.city">{{address.city}}</span>
    <span *ngIf="address">{{address.state}} {{address.zipCode}}</span></h4>
  <div class="property-info">
    <span *ngIf="details.side != 'buy' && propertyDetails.listPrice">{{propertyDetails.listPrice | currency:'USD':'symbol':'1.0'}}</span>
    <span *ngIf="details.side == 'buy' && propertyDetails.sellingPrice">{{propertyDetails.sellingPrice | currency:'USD':'symbol':'1.0'}}</span>
    <span>{{propertyDetails.noOfBedRooms}} Beds</span>
    <span>{{propertyDetails.noOfBathRooms}} Baths</span>
    <span *ngIf="propertyDetails.area">{{propertyDetails.area}}</span><span *ngIf="!propertyDetails.area">sqft</span>
  </div>
  <div class="end-date" *ngIf="details.estimatedClosingDate && details.status=='Invitation Triggered'">Closing Date {{getDateFormat(details.estimatedClosingDate)}} 
    <span *ngIf="dateDiff>0">&#x2022; {{dateDiff}}
      <span *ngIf="dateDiff ==1"> Day to Closing</span>
      <span *ngIf="dateDiff >1"> Days to Closing</span>
    </span>
    <span *ngIf="dateDiff==0">&#x2022; Today</span>
  </div>
  <div class="end-date" *ngIf="details.estimatedClosingDate && details.status=='completed'">Closed On {{getDateFormat(details.estimatedClosingDate)}} 
  </div>
  <div class="end-date" *ngIf="details.estimatedClosingDate && details.status=='cancelled'">Cancelled On {{getDateFormat(details.estimatedClosingDate)}} 
  </div>
  <div class="end-date" *ngIf="details.withdrawnDate && details.status=='Withdrawn'">Withdrawn On {{getDateFormat(details.withdrawnDate)}} 
  </div>
  <div class="end-date" *ngIf="details.expirationDate && details.status=='Expired'">Expired On {{getDateFormat(details.expirationDate)}} 
  </div>
  <div  *ngIf="details.status!='Invitation Triggered'" class="end-date">Transaction Status in HomePlace <span >&#x2022; {{details.status}}</span>
  </div>
  <p  class="buyer-block">
    <span *ngIf="details.side == 'buy'">Sale Side</span>
    <span *ngIf="details.side != 'buy'">Listing Side</span>
  </p>
</div>
