import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { AuthenticationService } from '../sevices/authentication.service';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  isAuthenticated: boolean;

  constructor(private authService: AuthenticationService, private router: Router) { }

  checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      // logged in so return true
      this.isAuthenticated = true;
    } else {

      try {
        if ('localStorage' in window && window['localStorage'] !== null)
          {
            // not logged in so redirect to login page
            const postLoginRedirectTo = state.url;
            localStorage.removeItem('post-login-redirect');
            if (postLoginRedirectTo) {
              localStorage.setItem('post-login-redirect', state.url);
            }
            this.authService.redirectToLogin();
            this.isAuthenticated = false;
          }
      } catch (e) {
        datadogLogs.logger.error('::AuthGuard::CheckAuth::API Error', { message: e});
        this.router.navigate(['/page-error']);
      }
    }
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAuth(route, state);
    return this.isAuthenticated;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAuth(route, state);
    return this.isAuthenticated;
  }
}
