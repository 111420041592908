import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs-compat';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss']
})
export class AgentInfoComponent implements OnInit {
  constructor(private transactionManagerService: TransactionManagerService,) { }

  value: string = 'test';
  private subscription = new Subscription();
  isLoading = false;
  agentImg: any;
  @Input() details: any;
  errorResponseCode: any;
  ngOnInit(): void {
  }
}
