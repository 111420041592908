import { Action } from '@ngrx/store';

export const UPDATE_ACCESS_TOKEN = '[OKTA] Update Access Token';
export const UPDATE_ID_TOKEN = '[OKTA] Update Id Token';

export class UpdateAccessToken implements Action {
    readonly type = UPDATE_ACCESS_TOKEN;
    constructor(public payload: any) { }
}

export class UpdateIdToken implements Action {
    readonly type = UPDATE_ID_TOKEN;
    constructor(public payload: any) { }
}

export type OktaAction = UpdateAccessToken
    | UpdateIdToken;
