import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: string | string, country: string): any {
    try {
      // const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
      //  return phoneNumber.formatNational();
      const areaCodeStr = phoneValue.slice(0,3);
    const midSectionStr = phoneValue.slice(3,6);
    const lastSectionStr = phoneValue.slice(6);
    return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`; 
    } catch (error) {
      return phoneValue;
    }
  }

}