import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OktaAuthenticationProvider } from '../sevices/auth-providers/okta-authentication.provider';
import { CreateEventModalComponent } from '../shared/modals/create-event-modal/create-event-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  user: string;
  createEventDialog;
  private listOfEventsTableColumns = ['userOktaId', 'sourceName',  'category', 'description', 'priority', 'startDate', 'action'];

  displayedColumns: string[] = this.listOfEventsTableColumns;
  datasource: MatTableDataSource<any>;

  public sortColumn = 'startDate';
  public sortOrder = 'desc';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private authService: OktaAuthenticationProvider,
    public dialog: MatDialog,
  ) {
    this.datasource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.user = this.authService.userName;
    this.datasource.data = [{
      id: 1,
      userOktaId: '0909090nkjasas',
      sourceName: 'Source Name',
      category: 'Category',
      description: 'Description',
      priority: '0',
      startDate: '20220711'
    }]
  
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  openCreateEventModal() {
    this.createEventDialog = this.dialog.open(CreateEventModalComponent, {
      panelClass: 'custom-dialog',
      closeOnNavigation: false
    });
  }

}
