<div *ngIf="!noResult">
<div class="todo-container">
  <div class="side-line"></div>
  <div *ngFor="let item of todoList; let i = index; first as isFirst; last as isLast" class="accordion-item">
    <mat-icon [ngClass]="{'first': isFirst, 'last': isLast}" matListIcon>{{
      item.status== 'Completed' ? 'check_circle' : 'radio_button_unchecked'
    }}</mat-icon>
    <button class="accordion" (click)="toggleAccordion($event, i)">
      <div class="tit-content">{{ item.title }}</div><span class="tit-icon"></span>
    </button>
    <div class="panel" hide="!item.isActive">
      <div class="sub-text">
        {{ item.description }}
      </div>
      <mat-list dense>
        <mat-list-item >
          <div class="grid">
          <div class="feature-d1" *ngIf="item.status !='Completed'">
            <span>Due Date &#183;</span>{{getDateFormat(item.dueDate)}} 
            <span *ngIf="getDateDiff(item.dueDate)">
              <span class="margin-0"> | </span>
              <span class="due-date">
                <span *ngIf="dateDiff == 0">Due Today </span>
                <span *ngIf="dateDiff == 1">{{dateDiff}} Day Overdue </span>
                <span *ngIf="dateDiff > 1">{{dateDiff}} Days Overdue </span>
                <span class="future-date" *ngIf="dateDiff == -1"> Due in {{positiveDate}} day </span>
                <span  class="future-date"*ngIf="dateDiff < -1"> Due in {{positiveDate}} days </span>
                <span></span>
              </span>
            </span>
          </div>
          <div class="feature-d1" *ngIf="item.status =='Completed'">
            <span>Completed On &#183;</span>{{getDateFormat(item.completionDate)}} 
          </div>
          <div class="feature-d2"><span>Priority &#183;</span>{{ item.priority }}</div>
        </div>
        </mat-list-item>
        <mat-list-item>
          </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
</div>
<div *ngIf="noResult" class="empty-state">
  <app-no-result-page pageTitle="There is nothing pending to do." ></app-no-result-page>
  </div>