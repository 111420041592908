import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root',
})
export class GetDetailsById extends Query<Response> {
  document = gql`
  query transactionManager ($id: Int) {
    transactionManager(id:$id) {
    }
}
  `;
}

