import {Component, Input, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';

@Component({
  selector: 'app-my-journey',
  templateUrl: './my-journey.component.html',
  styleUrls: ['./my-journey.component.scss']
})
export class MyJourneyComponent implements OnInit {

  private subscription = new Subscription();
  isLoading = false;
  myJourneyList: any = null;
  errorResponseCode: any;
  constructor(
    private transactionManagerService: TransactionManagerService,
  ) { }
  @Input() value: string;
  displayedColumns: string[] = ['documentName', 'sourceSystem', 'documentId', 'status'];
  ngOnInit(): void {

    this.getDocuments();
  }

  private getDocuments() {

    this.subscription.add(
      this.transactionManagerService.getTodos().subscribe(
        response => {
          this.isLoading = false;
          if (response && response.length) {
            this.myJourneyList = response;
          } else {
            const errorResponse = response.errors[0];
            this.errorResponseCode = errorResponse['extensions'].errorCode;
          }
        },
        err => {
          this.isLoading = false;
          this.errorResponseCode = 404;
        }
      )
    );
  }
  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle('active');
    if (this.myJourneyList[index].isActive) {
      this.myJourneyList[index].isActive = false;
    } else {
      this.myJourneyList[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
