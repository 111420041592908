<div class="main-content">
  <div class="listing-content">
    <app-listing-info
      *ngIf="transactionDetails"
      [details]="transactionDetails"
    ></app-listing-info>
    <mat-tab-group mat-stretch-tabs animationDuration="0ms">
      <mat-tab label="TO DO">
        <app-todo [transactionId] = "transactionId"></app-todo>
      </mat-tab>
      <mat-tab label="MY DOCS">
        <app-document [value]="value" [transactionId] = "transactionId"></app-document>
      </mat-tab>
      <mat-tab  label="MY TEAM">
        <app-my-team [value]="value" [transactionId] = "transactionId"></app-my-team>
      </mat-tab>
      <!-- <mat-tab label="MY JOURNEY">
        <app-my-journey [value]="value"></app-my-journey>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>
