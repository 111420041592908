<div class="dashboard">
    <div class="banner">
        <h1>Welcome {{user}}</h1>
        <p>Select an action from the list below</p>
    </div>
    <div class="content">
        <mat-card>
            <mat-title><h2>Homeplace Administration</h2></mat-title>
            <mat-card-content>
                <ul>
                    <li>
                        <a (click)="openCreateEventModal()"><i class="fal fa-bell"></i> Create Event</a>
                    </li>
                    <li>
                        <a [routerLink]="['']"><i class="fal fa-bell"></i> List of Events</a>
                    </li>
                    <li>
                        <a [routerLink]="['']"><i class="fal fa-bell"></i> Events Watch</a>
                    </li>
                    <li>
                        <a [routerLink]="['']"><i class="fal fa-clone"></i> Registered Apps</a>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="events-list">
        <mat-card class="left-blk">
            <h2 class="mat-h2">List of Events <button mat-icon-button color="primary"><i
                        class="fas fa-sync"></i></button></h2>

            <div class="title-blk">
                <p>Below are the list of events created by users and apps. You can filter the events based on multiple
                    parameters</p>
                <mat-form-field appearance="outline">
                    <mat-label>Filter</mat-label>
                    <input matInput #filter>
                </mat-form-field>
            </div>
            <div class="table-wrapper">
                <table mat-table [dataSource]="datasource" matSort [matSortActive]="sortColumn"
                    [matSortDirection]="sortOrder">

                    <!-- Image and name Column -->
                    <ng-container matColumnDef="id" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.id}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="userOktaId" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Okta Id </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.userOktaId}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="sourceName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Source Name </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.sourceName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.category}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.description}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="priority">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.priority}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
                        <td mat-cell *matCellDef="let item">
                            {{item.startDate}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                        <td mat-cell *matCellDef="let item">
                            <button mat-raised-button color="primary">View</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
        </mat-card>
    </div>
<div>
