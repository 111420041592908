<div class="wrapper-grid center-layout" >
  <div class="content" id="content-wrap">
  <div class="component-content p-t-6">
    <div class="message-wrapper m-t-4 m-t-sm-0 m-t-md-5 m-t-lg-0 m-t-xl-7 m-b-0 m-b-md-5 m-l-lg-6">
      <div class="page-forbidden">
        <h1 class="error-page-header">{{pageTitle}}</h1>
        <h2 class="error-page-content m-t-2">{{pageContent1}}</h2>
        <h2 class="error-page-content m-t-2">{{pageContent2}}</h2>
      </div>
    </div>
    <div class="image-wrapper">
      <img src="assets/images/page-forbidden.svg">
    </div>
  </div>
  </div>
</div>
