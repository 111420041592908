<div class="container text-center">
  <div class="wrapper">
    <div>
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
      <br><br>
      <h1 class="lead"></h1>
    </div>
    <!-- <div *ngIf = "!BlockedCookies">
      <app-error-page></app-error-page>
    </div> -->
    
  </div>
</div>
