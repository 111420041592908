/**
 * `model` for defining the properties of a **Snackbar** object to be injected to the `SnackbarComponent`.
 */
export class Snackbar {
    /**
     * `optional` property to set the action to be fired from within the `SnackbarComponent`. If a set of values is required, it
     * can be defined here. Currently being set as an empty `string` by **default**.
     */
    action?: string;

    /**
     * Actual message to display in the `SnackbarComponent`.
     */
    message: string;

    /**
     * All available types of `Snackbar`. If more action types are needed, they must be added here.
     */
    type?: 'success' | 'warning' | 'error' | 'info';
  }
