import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransactionManagerService } from '../../sevices/transaction-manager.service';

import { datadogLogs } from '@datadog/browser-logs';
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class documentComponent implements OnInit {
  constructor(private transactionManagerService: TransactionManagerService) {}
  private subscription = new Subscription();
  isLoading = false;
  documentList: any = null;
  noResult = false;
  errorResponseCode: any;
  @Input() value: string;
  @Input() transactionId: string;

  ngOnInit(): void {
    this.getDocuments();
  }

  toggleAccordian(event, index) {
    let element = event.target;
    if(element.className === 'title') {
      element = element.parentElement;
    } else {
      if(element.classList.contains('mat-icon')){
        element = element.parentElement.parentElement
      }
    }
    element.classList.toggle('active');
    if (this.documentList[index].isActive) {
      this.documentList[index].isActive = false;
    } else {
      this.documentList[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  stopAction(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private getDocuments() {
    this.subscription.add(
      this.transactionManagerService.getDocuments(this.transactionId).subscribe(
        (response) => {
          this.isLoading = false;
          if (response && response.length) {
            this.documentList = response;
            this.noResult = false;
          } else {
            const errorResponse = response.errors[0];
            this.noResult =true;
            this.errorResponseCode = errorResponse.extensions.errorCode;
          }
        },
        (err) => {
          this.isLoading = false;
          this.noResult =true;
          this.errorResponseCode = 404;
          datadogLogs.logger.error('::DocumentComponent::getDocuments::API Error', { message: err});
        }
      )
    );
  }

  getDateFormat(date) {
    const ads = new Date(date.slice(0,-1));
    return ads.toLocaleDateString('en-US');
  }

  getStatusFormat(status) {
    return status.replace(/([a-z])([A-Z])/g, `$1 $2`);
  }
}
