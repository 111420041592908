import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import {  
  Http,  
  Headers,  
  RequestOptions,  
  Response  
} from '@angular/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class TransactionManagerService {
  
  constructor(
    private httpClient: HttpClient
  ) { }

  isFullScreen = new BehaviorSubject<boolean>(false);


  getTransactionDetails(id?: string): Observable < any > {
    // const includes = ['property', 'preferredagent', 'mortgage', 'consumer', 'agents'];
    const include = 'include=agents&include=preferredagent&include=mortgage&include=property'
    return this.httpClient.get(environment.apiBaseURL + 'v1.0/c2sc/agent/transaction/'+id+'?'+include).map((response: any) => { 
      return <any > response 
    //  return this.httpClient.get('./assets/homeplaceweb.json').map((response: any) => {  
    //     return <any > response.transactiondetails  
    }).catch(this.handleError);  
  } 
  getTodos(id?: string): Observable < any > {
    return this.httpClient.get(environment.apiBaseURL + 'v1.0/c2sc/agent/transaction/'+id+'/todo').map((response: any) => {
      return <any > response
    //return this.httpClient.get('./assets/homeplaceweb.json').map((response: any) => {
      // return <any > response.todos  
  }).catch(this.handleError);  
}  

getDocuments(id?: string): Observable < any > {  
   return this.httpClient.get(environment.apiBaseURL + 'v1.0/c2sc/agent/transaction/'+id+'/documents').map((response: any) => {
    return <any > response
    // return this.httpClient.get('./assets/homeplaceweb.json').map((response: any) => {  
    //   return <any > response.documents  
  }).catch(this.handleError);  
}  

getServiceProviders(id?: string): Observable < any > {  
  
return this.httpClient.get(environment.apiBaseURL + 'v1.0/c2sc/agent/transaction/'+id+'/serviceprovider').map((response: any) => {
  return <any > response  
    // return this.httpClient.get('./assets/homeplaceweb.json').map((response: any) => {  
    //   return <any > response.serviceproviders  
  }).catch(this.handleError);  
}  

private handleError(errorResponse: Response) {  
    return Observable.throw(errorResponse.json().error || "Server error");  
}  


}
